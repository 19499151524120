<template>
	<shutter-panel ref="shutterPanel" />
</template>


<script type="text/javascript">
    import Shutter from "@/mixins/Shutter.js"
    import ShutterActs from '@/mixins/shutters-manager/Acts.js'

	export default {
		name: "HorseAllActes",
		mixins: [
            Shutter, 
            ShutterActs,
        ],
		props: {
            horse_id: { type: Number, default: 0 }
		},
		mounted() {
            this.init_component()
		},
		methods: {
			init_component() {
				this.setupHorseAllActes(this.horseId)
			}
        },
        computed: {
			horseId() {
				return parseInt(this.$route.params.horse_id)
			}
		},
	}
</script>
